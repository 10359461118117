/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
import XTextarea from 'vux/src/components/x-textarea';
import ViewBox from 'vux/src/components/view-box';
import PopupPicker from 'vux/src/components/popup-picker';
import UploadImage from '@/components/UploadImage/UploadImage';
export default {
  name: 'RepairInfo',
  components: {
    XInput: XInput,
    Group: Group,
    XButton: XButton,
    ViewBox: ViewBox,
    XTextarea: XTextarea,
    PopupPicker: PopupPicker,
    UploadImage: UploadImage
  },
  data: function data() {
    return {
      repairAddress: '',
      initiatorTel: '',
      repairType: [],
      initiatorRemark: '',
      imgList: [],
      //图片列表数组
      limitNum: 5,
      //最多上传图片张数
      currDate: '',
      dicList: [[{
        name: '',
        value: ''
      }]]
    };
  },
  computed: {},
  methods: {
    submit: function submit() {
      var _this = this;

      if (this.repairAddress === '' || this.repairAddress === undefined) {
        this.$utils.Tools.toastShow('请输入故障地点');
        return;
      }

      if (this.repairType[0] === '' || this.repairType[0] === undefined) {
        this.$utils.Tools.toastShow('请选择维修类别');
        return;
      }

      this.$utils.Tools.loadShow();
      var temp = [];

      var _iterator = _createForOfIteratorHelper(this.imgList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var imgs = _step.value;
          temp.push(imgs.serverId);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var imgPath = temp.join(',');
      var params = {
        repairType: this.repairType[0],
        repairAddress: this.repairAddress,
        initiatorTel: this.initiatorTel,
        initiatorRemark: this.initiatorRemark,
        repairState: '0',
        readFlag: '0',
        initiatorUser: this.$utils.Store.getItem('userSerial'),
        repairPhoto: imgPath
      };
      this.$api.Repairs.insert(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.$utils.Tools.toastShow(msg);

          if (_this.$route.params.notification) {
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            _this.$router.replace('/RepairList');
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getDictInfo: function getDictInfo() {
      var _this2 = this;

      this.dicList = [];
      var tempList = [];
      var params = {//   tenancyId: "0",
        //   typeCode: this.$constant.repairTypeDict
      };
      this.$api.Repairs.selectListByEntity(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          var _iterator2 = _createForOfIteratorHelper(data),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var item = _step2.value;
              tempList.push({
                name: item.typeName,
                value: item.typeId
              });
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          _this2.dicList.push(tempList);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this3 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.currDate = data.sysDate;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.getSysDate();
    this.getDictInfo();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};